import { Exclude, Expose, Type } from "class-transformer";

// -------------------- DTOs for list view -------------------- //
export class PatientCaseListDTO {
    @Expose()
    _id?: string;

    @Expose()
    publish?: boolean;

    @Expose()
    key?: string;

    @Expose()
    title?: string;

    @Expose()
    downloadable?: boolean;

    @Expose()
    @Type(() => Date)
    created?: Date;

    @Expose()
    @Type(() => Date)
    updated?: Date;

    @Expose()
    version?: number;
}

// -------------------- DTOs for create / update  -------------------- //
export type Gender = "Male" | "Female";

export enum LearningEnum {
    MultipleChoice = "MultipleChoice",
    Drawing = "Drawing"
}

export enum CategoryEnum {
    DoctorsOffice = "DoctorsOffice",
    Lab = "Lab",
    Biopsy = "Biopsy",
    Imaging = "Imaging",
    OutpatientClinic = "OutpatientClinic",
    Outlook = "Outlook",
    Pathology = "Pathology",
    ExpertInsights = "ExpertInsights",
    Treatment = "Treatment"
}

export class AnswerDTO {
    @Expose()
    _id?: string;
    @Expose()
    title?: string;
    @Expose()
    isCorrect?: boolean;
}

export class LearningDTO {
    @Expose()
    _id?: string;
    @Expose()
    showCategoryPicture?: boolean;
    @Expose()
    visitingDate?: string;
    @Expose()
    affiliatedCategoryId?: CategoryEnum;
    @Expose()
    title?: string;
    @Expose()
    type?: LearningEnum;
    @Expose()
    callToAction1?: string;
    @Expose()
    callToAction2?: string;

    @Expose()
    @Type(() => AnswerDTO)
    answers?: AnswerDTO[];

    @Expose()
    picture?: string; // file.name from "File" should be placed here if a new file will/should be uploaded
    @Expose()
    thumbnail?: string; // file.name from "File" should be placed here if a new file will/should be uploaded
    @Expose()
    solutionPicture?: string; // file.name from "File" should be placed here if a new file will/should be uploaded
    @Expose()
    solutionPictureThumbnail?: string; // file.name from "File" should be placed here if a new file will/should be uploaded
    @Expose()
    solutionRichContent?: string;
    @Expose()
    highlight?: boolean;
    @Expose()
    hasOnlyOneValidAnswer?: boolean;
    @Expose()
    hideShowVotesButton?: boolean;
}

export class SlidePictureDTO {
    @Expose()
    _id?: string;
    @Expose()
    title?: string;
    @Expose()
    file?: string; // file.name from "File" should be placed here if a new file will/should be uploaded
    @Expose()
    thumbnail?: string; // file.name from "File" should be placed here if a new file will/should be uploaded
}

export class SlideDTO {
    @Expose()
    _id?: string;
    @Expose()
    showCategoryPicture?: boolean;
    @Expose()
    visitingDate?: string;
    @Expose()
    subheading?: string;
    @Expose()
    title?: string;
    @Expose()
    shortTitle?: string;
    @Expose()
    richContent?: string;

    @Expose()
    @Type(() => SlidePictureDTO)
    pictures?: SlidePictureDTO[];

    @Expose()
    highlight?: boolean;

    @Expose()
    disclaimer?: string;
}

export class CategoryDTO {
    @Expose()
    _id?: string;
    @Expose()
    id?: CategoryEnum;

    @Expose()
    @Type(() => SlideDTO)
    slides?: SlideDTO[];
}

export class ReferenceDTO {
    @Expose()
    _id?: string;
    @Expose()
    id?: string;
    @Expose()
    richContent?: string;
}

export class PatientCaseDetailDTO {
    @Expose()
    _id?: string;
    @Expose()
    publish?: boolean;
    @Expose()
    key?: string;
    @Expose()
    title?: string;
    @Expose()
    subtitle?: string;
    @Expose()
    indication?: string[];
    @Expose()
    cancerType?: string[];
    @Expose()
    author?: string[];
    @Expose()
    gender?: Gender;
    @Expose()
    age?: number;

    @Expose()
    @Type(() => CategoryDTO)
    categories?: CategoryDTO[];

    @Expose()
    @Type(() => LearningDTO)
    learnings?: LearningDTO[];

    @Expose()
    @Type(() => ReferenceDTO)
    references?: ReferenceDTO[];

    @Expose()
    @Type(() => Date)
    @Exclude({ toPlainOnly: true })
    created?: Date;

    @Expose()
    @Type(() => Date)
    @Exclude({ toPlainOnly: true })
    updated?: Date;

    @Expose()
    version?: number;
}
