import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class HttpLoadingService {
    private httpRequestsSubject = new BehaviorSubject<number>(0);
    httpRequests = this.httpRequestsSubject.asObservable();

    constructor() {}

    increment() {
        this.httpRequestsSubject.next(this.httpRequestsSubject.getValue() + 1);
    }

    decrement() {
        this.httpRequestsSubject.next(this.httpRequestsSubject.getValue() > 0 ? this.httpRequestsSubject.getValue() - 1 : 0);
    }
}
