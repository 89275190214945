import { Component, HostListener } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "@impacgroup/angular-oauth-base";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html"
})
export class AppComponent {
    constructor(translate: TranslateService, private authService: AuthService) {
        /* MOVED TO AppModule!
        // this language will be used as a fallback when a translation isn"t found in the current language
        translate.setDefaultLang("en");

        translate.addLangs(["en"]);

        // the lang to use, if the lang isn"t available, it will use the current loader to get them
        translate.use("en");
        */
    }

    /*
    @HostListener("window:beforeunload", [ "$event" ])
    beforeUnloadHander(event) {
        this.authService.logout().subscribe((result) => {
        });
    }
    */
}
