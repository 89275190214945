import { Component, OnInit } from "@angular/core";
import { trigger, state, animate, transition, style } from "@angular/animations";
import { HttpLoadingService } from "../../global/api-handling/HttpLoadingService";
import { BaseComponent } from "@impacgroup/angular-baselib";

@Component({
    selector: "app-page-loader",
    templateUrl: "./page-loader.component.html",
    styleUrls: ["./page-loader.component.scss"],
    animations: [
        trigger("togglePageLoader", [state("true", style({ transform: "translate3d(0,0,0)" })), state("void", style({ transform: "translate3d(0,50px,0)" })), transition(":enter", animate("300ms")), transition(":leave", animate("300ms"))])
    ]
})
export class PageLoaderComponent extends BaseComponent implements OnInit {
    public loading: boolean = false;

    constructor(private httpLoadingService: HttpLoadingService) {
        super();
    }

    ngOnInit() {
        this.subscriptions.push(
            this.httpLoadingService.httpRequests.subscribe((result) => {
                this.loading = result > 0;
            })
        );
    }
}
