export const fileToBase64 = async (file: File): Promise<string | ArrayBuffer> => {
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
            resolve(e.target.result);
        };
        reader.onerror = (err) => {
            reject(err);
        };
    });
};

// export const base64ToFile = async (base64: string | ArrayBuffer): Promise<File> => {
//     if (base64 instanceof ArrayBuffer) {
//         throw Error("ArrayBuffer currently not supported");
//     }
//     const base64Response = await fetch(base64);
//     const blob = await base64Response.blob();
//     const file: File = new File([blob], uuid(), { type: blob.type });

//     return file;
// };

// export const fileToObservable = (file: File): Observable<File> => {
//     return of(file);
// };
