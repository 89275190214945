import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { FullLayoutComponent } from "./layout/layouts/layout-full/layout.component";
import { SingleLayoutComponent } from "./layout/layouts/layout-single/layout.component";
import { AuthenticationGuard } from "@impacgroup/angular-oauth-base";
import { OrganizationsModule, RolesModule, UsersModule } from "@impacgroup/angular-access-management";
import { ProfileModule, ResetPasswordModule } from "@impacgroup/angular-selfservice";
import { LanguagesModule } from "@impacgroup/angular-languages";
import { MailTemplatesModule, SmtpMailAccountsModule } from "@impacgroup/angular-mailtemplates";
import { SystemLogMessagesModule, SystemJobPerformancesModule,  SystemErrorsModule } from "@impacgroup/angular-systemhealth";
import { PatientCasesModule } from "./modules/patientcases/patientcases.module";

const routes: Routes = [
    {
        path: "",
        component: FullLayoutComponent,
        canActivate: [AuthenticationGuard],
        canActivateChild: [AuthenticationGuard],
        children: [
            // Home
            {
                path: "",
                redirectTo: "home",
                pathMatch: "full"
            },
            {
                path: "home",
                loadChildren: () => import("./pages/home/home.module").then((m) => m.HomeModule)
            },
            {
                path: "usermanagement",
                children: [
                    {
                        path: "",
                        redirectTo: "users",
                        pathMatch: "full"
                    },
                    {
                        path: "users",
                        children: UsersModule.getRoutes()
                    },
                    {
                        path: "roles",
                        children: RolesModule.getRoutes()
                    },
                    {
                        path: "organizations",
                        children: OrganizationsModule.getRoutes()
                    }
                ]
            },
            {
                path: "profile",
                children: ProfileModule.getRoutes()
            },
            {
                path: "languages",
                children: LanguagesModule.getRoutes()
            },
            {
                path: "mail",
                children: [
                    {
                        path: "",
                        redirectTo: "mailaccounts",
                        pathMatch: "full"
                    },
                    {
                        path: "mailaccounts",
                        children: SmtpMailAccountsModule.getRoutes()
                    },
                    {
                        path: "mailtemplates",
                        children: MailTemplatesModule.getRoutes()
                    }
                ]
            },
            {
                path: "patientcases",
                children: PatientCasesModule.getRoutes()
            },
            {
                path: "systemhealth",
                children: [
                    {
                        path: "",
                        redirectTo: "logs",
                        pathMatch: "full"
                    },
                    {
                        path: "logs",
                        children: SystemLogMessagesModule.getRoutes()
                    },
                    {
                        path: "jobs",
                        children: SystemJobPerformancesModule.getRoutes()
                    },
                    {
                        path: "errors",
                        children: SystemErrorsModule.getRoutes()
                    }
                ]
            }
        ]
    },
    {
        path: "",
        component: SingleLayoutComponent,
        children: [
            {
                path: "login",
                loadChildren: () => import("./pages/login/login.module").then((m) => m.LoginModule)
            },
            {
                path: "resetpassword",
                children: ResetPasswordModule.getRoutes()
            },
            {
                path: "404",
                loadChildren: () => import("./pages/four-zero-four/four-zero-four.module").then((m) => m.FourZeroFourModule)
            },
            {
                path: "**",
                redirectTo: "/404"
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
