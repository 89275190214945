<div class="modal-content">
    <div class="modal-header"><h4 class="modal-title">Edit Reference</h4></div>
    <div class="modal-body">
        <div class="form-group">
            <label class="label">Reference ID</label>
            <input type="text" class="form-control" [(ngModel)]="currentReference.id" />
        </div>
        <div class="form-group">
            <label class="label">Rich Content</label>
            <ckeditor name="ckEditor" [(ngModel)]="currentReference.richContent" [config]="ckeConfig"></ckeditor>
        </div>
    </div>
    <div class="modal-footer"><button type="button" class="btn btn-link" (click)="clickOk()">OK</button><button type="button" class="btn btn-link" (click)="close()">Close</button></div>
</div>
