import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router, ActivatedRoute } from "@angular/router";
import { saveAs } from "file-saver";
import { BsModalService } from "ngx-bootstrap/modal";
import { PatientCasesService } from "./patientcases.service";
import { AlertService, BaseDatatableComponent, ConfirmDialogComponent, DataTableParameters } from "@impacgroup/angular-baselib";
import { PatientCaseListModel } from "./viewmodels/PatientCase";
import { PatientCasesMainComponent } from "./patientcases.main.component";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-patientcases",
    templateUrl: "./patientcases.component.html",
    styleUrls: ["patientcases.component.scss"]
})
export class PatientCasesComponent extends BaseDatatableComponent implements OnInit {
    public UTCDATEFORMAT: string = "";

    rows: PatientCaseListModel[] = [];

    constructor(
        private patientCasesService: PatientCasesService,
        private translateService: TranslateService,
        private router: Router,
        private route: ActivatedRoute,
        private modalService: BsModalService,
        private alertService: AlertService,
        parent: PatientCasesMainComponent
    ) {
        super(translateService, {
            stateSaveMode: patientCasesService.datatableStateSaveMode,
            stateSaveComponent: parent,
            stateSaveStorageKey: "dtstate_patientcases_list"
        });

        this.dtOptions.order = [[4, "desc"]];
        this.UTCDATEFORMAT = this.patientCasesService.UTCDATEFORMAT;
    }

    ngOnInit() {}

    _dataTableTrigger() {
        return (dataTableParameters: DataTableParameters, callback) => {
            // get api query parameters by datatable
            const queryParams = this.getApiQueryParamsByDataTable(dataTableParameters);

            this.subscriptions.push(
                this.patientCasesService.list(queryParams).subscribe((result) => {
                    this.rows = result.list;

                    callback({
                        recordsTotal: result.total,
                        recordsFiltered: result.count,
                        data: []
                    });
                })
            );
        };
    }

    _dataTableColums() {
        return [
            { data: "publish", orderable: false },
            { data: "_id", orderable: true },
            { data: "key", orderable: true },
            { data: "title", orderable: true },
            { data: "created", orderable: true },
            { data: "updated", orderable: true },
            { data: "actions", orderable: false }
        ];
    }

    uploadPatientCase(files: FileList) {
        if (files.length === 0) {
            return;
        }

        const mimeType = files[0].type;
        if (mimeType.match(/zip\/*/) == null) {
            this.alertService.addCustomErrorByI18nId("patientcases.list.validation.upload_zip");
            return;
        }

        const file: File = files[0];

        this.subscriptions.push(
            this.patientCasesService.import(file).subscribe((result) => {
                this.alertService.addSuccess(this.translateService.instant("patientcases.list.upload_succes"));
                this.rerenderDatatable();
            })
        );
    }

    downloadPatientCase(id: string) {
        this.subscriptions.push(
            this.patientCasesService.downloadZip(id).subscribe((result) => {
                saveAs(result.blob, result.filename);
            })
        );
    }

    deletePatientCase(id: string) {
        const initialState = {
            title: this.translateService.instant("patientcases.list.delete.title"),
            message: this.translateService.instant("patientcases.list.delete.message"),
            closeBtnName: this.translateService.instant("global.buttons.close"),
            confirmBtnName: this.translateService.instant("global.buttons.ok")
        };
        const bsModalRef = this.modalService.show(ConfirmDialogComponent, { initialState });
        bsModalRef.content.confirmCallback = () => {
            this.subscriptions.push(
                this.patientCasesService.delete(id).subscribe((result) => {
                    this.alertService.addSuccess(this.translateService.instant("patientcases.list.delete.success"));
                    this.rerenderDatatable();
                })
            );
        };
    }

    editPatientCase(id: string, key: string) {
        this.router.navigate([id], { relativeTo: this.route });
    }

    showPatientCase(id: string, key: string) {
        // window.open(`${environment.frontedAppUrl}${encodeURIComponent(key)}/${id}`, "_blank").focus();
        window.open(`${environment.frontedAppUrl}cases/${id}`, "_blank").focus();
    }
}
