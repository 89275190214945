import { Location } from "@angular/common";
import { ChangeDetectorRef, Component, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AlertService, BaseComponent, BaseComponentMode } from "@impacgroup/angular-baselib";
import { TranslateService } from "@ngx-translate/core";
import { CKEditor4 } from "ckeditor4-angular";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { of } from "rxjs";
import { environment } from "src/environments/environment";
import { CategoryEnum, Gender, LearningEnum } from "../../modules/patientcases/dtos/PatientCase";
import { ReferenceEditorComponent } from "./components/reference-editor/reference-editor.component";
import { ReferenceSelectorComponent } from "./components/reference-selector/reference-selector.component";
import { PatientCasesService, visitingDateComparator } from "./patientcases.service";
import { LearningCategoryEnum, LearningModel, PatientCaseDetailModel, ReferenceModel, SlideModel } from "./viewmodels/PatientCase";
const INVALID_FILE = " Invalid file.";
const INVALID_IMAGE = " Invalid image.";
@Component({
    selector: "app-patientcase-editor",
    templateUrl: "./patientcase-editor.component.html",
    styleUrls: ["patientcase-editor.component.scss"]
})
export class PatientCaseEditorComponent extends BaseComponent {
    @ViewChild("ckEditorSlide")
    public ckEditorSlide: CKEditor4.Editor;

    public ckeConfig: CKEditor4.Config = {
        extraPlugins: "divarea",
        forcePasteAsPlainText: false,
        allowedContent: true,
        removePlugins: "'exportpdf'",
        removeButtons: "",
        toolbar: [
            { name: "clipboard", items: ["Cut", "Copy", "Paste", "PasteText", "-", "Undo", "Redo"] },
            { name: "editing", items: ["Scayt"] },
            { name: "links", items: ["Link", "Unlink", "Anchor"] },
            { name: "insert", items: ["Image", "Table", "HorizontalRule", "SpecialChar"] },
            { name: "tools", items: ["Maximize"] },
            { name: "document", items: ["Source"] },
            "/",
            { name: "basicstyles", items: ["Bold", "Italic", "Strike", "-", "Subscript", "Superscript", "RemoveFormat"] },
            { name: "paragraph", items: ["NumberedList", "BulletedList", "-", "Outdent", "Indent", "-", "Blockquote"] },
            { name: "styles", items: ["Styles", "Format"] }
        ]
    };

    public UTCDATEFORMAT: string = "";
    public datePickerValue: Date;
    public datePickerConfig: Partial<BsDatepickerConfig> = {
        containerClass: "date-picker",
        dateInputFormat: "DD.MM.YYYY"
    };

    public currentPatientCase: PatientCaseDetailModel = {};
    public currentCategoryId: LearningCategoryEnum | CategoryEnum;
    public currentSlide: SlideModel = {};
    public currentLearning: LearningModel = {};
    public currentSlideIndex: number | undefined = undefined;
    public currentReference: ReferenceModel;

    public genderLabels: Gender[] = ["Male", "Female"];
    public categoryLabels = [...Object.values(CategoryEnum), ...Object.values(LearningCategoryEnum)];
    public affiliatedCategoryLabels = Object.values(CategoryEnum);
    public learningTypeLabels = Object.values(LearningEnum);
    public selectedCategory: string;
    public richContent: string;
    public htmlContentInfoTitle: string;
    public htmlContentInfoText: string;

    public modalRef: BsModalRef;

    constructor(
        private patientCasesService: PatientCasesService,
        private translateService: TranslateService,
        private cdr: ChangeDetectorRef,
        private route: ActivatedRoute,
        private _location: Location,
        private alertService: AlertService,
        private modalService: BsModalService
    ) {
        super();
        this.UTCDATEFORMAT = this.patientCasesService.UTCDATEFORMAT;

        this.subscriptions.push(
            this.route.params.subscribe((params) => {
                if (params.id) {
                    this.initEditMode(params.id);
                } else {
                    this.initAddMode();
                }
            })
        );

        this.htmlContentInfoTitle = this.translateService.instant("patientcases.editor.htmlcontent.info.title");
        this.htmlContentInfoText = this.translateService.instant("patientcases.editor.htmlcontent.info.text");
    }

    public onReady(event: any) {
        this.ckEditorSlide = event.editor;
    }

    public initAddMode() {
        this.MODE = BaseComponentMode.ADD;
    }

    public initEditMode(caseId: string) {
        this.MODE = BaseComponentMode.EDIT;
        this.patientCasesService.getById(caseId).subscribe((patientCaseToEdit) => {
            this.currentPatientCase = patientCaseToEdit;
        });
    }

    public savePatientCase() {
        if (this.MODE === BaseComponentMode.ADD) {
            if (this.currentPatientCase) {
                this.patientCasesService.add(this.currentPatientCase).subscribe((result) => {
                    this.currentPatientCase = result;
                    this.MODE = BaseComponentMode.EDIT;
                    this.alertService.addSuccess(this.translateService.instant("patientcases.editor.add.success"));
                });
            }
        }

        if (this.MODE === BaseComponentMode.EDIT) {
            if (this.currentPatientCase) {
                this.patientCasesService.update(this.currentPatientCase).subscribe((result) => {
                    this.currentPatientCase = result;
                    this.alertService.addSuccess(this.translateService.instant("patientcases.editor.edit.success"));
                });
            }
        }
    }

    public handleFileInput(files: File[], pictureType: string, index: number) {
        if (files && files.length > 0) {
            if (this.currentCategoryId !== LearningCategoryEnum.Learning) {
                switch (pictureType) {
                    case "picture-thumbnail":
                        this.currentSlide.pictures[index].thumbnail = of(files[0]);
                        this.currentSlide.pictures[index].thumbnailTouched = true;
                        break;
                    case "picture":
                        this.currentSlide.pictures[index].file = of(files[0]);
                        this.currentSlide.pictures[index].fileTouched = true;
                        break;
                    default:
                        break;
                }
            } else {
                switch (pictureType) {
                    case "draw-thumbnail":
                        this.currentLearning.thumbnail = of(files[0]);
                        this.currentLearning.thumbnailTouched = true;
                        break;
                    case "draw":
                        this.currentLearning.picture = of(files[0]);
                        this.currentLearning.pictureTouched = true;
                        break;
                    case "solution-thumbnail":
                        this.currentLearning.solutionPictureThumbnail = of(files[0]);
                        this.currentLearning.solutionPictureThumbnailTouched = true;
                        break;
                    case "solution":
                        this.currentLearning.solutionPicture = of(files[0]);
                        this.currentLearning.solutionPictureTouched = true;
                        break;
                    default:
                        break;
                }
            }
            this.cdr.markForCheck();
        }
    }

    public editReference(refIndex: number) {
        let initialState = {};
        if (refIndex >= 0) {
            initialState = { currentReference: { id: this.currentPatientCase.references[refIndex].id, richContent: this.currentPatientCase.references[refIndex].richContent } };
        } else {
            initialState = { currentReference: { id: "", richContent: "" } };
        }

        this.modalRef = this.modalService.show(ReferenceEditorComponent, { initialState, class: "big" });
        let newSubscriber = this.modalService.onHide.subscribe((r) => {
            newSubscriber.unsubscribe();
            if (this.modalRef.content.currentReference.id) {
                if (refIndex >= 0) {
                    this.currentPatientCase.references[refIndex] = this.modalRef.content.currentReference;
                } else {
                    this.currentPatientCase.references.push(this.modalRef.content.currentReference);
                }
                this.cdr.markForCheck();
            }
        });
    }

    public removeReference(refIndex: number) {
        this.currentPatientCase.references.splice(refIndex, 1);
    }

    public selectReference(event) {
        const initialState = { caseReferences: this.currentPatientCase.references.map((ref) => ref.id) };
        const modalRef: BsModalRef = this.modalService.show(ReferenceSelectorComponent, { initialState });
        let modalSubscriber = this.modalService.onHide.subscribe((r) => {
            modalSubscriber.unsubscribe();
            if (modalRef.content.selectedReferenceId) {
                this.ckEditorSlide.focus();
                this.ckEditorSlide.insertHtml("<span class='referenz' ref-id='" + modalRef.content.selectedReferenceId + "'>[insert text here]</span>");
                this.cdr.markForCheck();
            }
        });
    }

    public addNewPicturePair() {
        if (!(this.currentSlide.pictures instanceof Array)) this.currentSlide.pictures = [];
        this.currentSlide.pictures.push({ title: "", fileTouched: false, thumbnailTouched: false });
    }
    public removePicture(idx: number) {
        this.currentSlide.pictures.splice(idx, 1);
    }

    public addAnswer() {
        if (!(this.currentLearning?.answers instanceof Array)) this.currentLearning.answers = [];
        this.currentLearning.answers.push({ title: "", isCorrect: false });
    }
    public removeAnswer(idx: number) {
        this.currentLearning.answers.splice(idx, 1);
    }
    public removeSlide(slideIndex: number) {
        this.currentPatientCase.slides.splice(slideIndex, 1);
    }
    public editSlide(slideIndex: number, template: TemplateRef<any>) {
        if (this.currentPatientCase.slides[slideIndex]?.category !== LearningCategoryEnum.Learning) {
            this.currentCategoryId = this.currentPatientCase.slides[slideIndex].category;
            this.currentSlide = this.currentPatientCase.slides[slideIndex] as SlideModel; // we now it is a SlideModel, since the category is NOT LearningCategoryEnum.Learning
            this.richContent = this.currentSlide.richContent;
            this.currentSlideIndex = slideIndex;
        }

        if (this.currentPatientCase.slides[slideIndex]?.category === LearningCategoryEnum.Learning) {
            this.currentCategoryId = this.currentPatientCase.slides[slideIndex].category;
            this.currentLearning = this.currentPatientCase.slides[slideIndex] as LearningModel; // we now it is a LearningModel, since the category IS LearningCategoryEnum.Learning
            this.richContent = this.currentLearning.solutionRichContent;
            this.currentSlideIndex = slideIndex;
        }
        event.preventDefault();
    }

    public emptyViewModelAndCollapseEditor() {
        this.currentCategoryId = undefined;
        this.currentSlide = {};
        this.currentSlide.pictures = [];
        this.currentLearning = {};
        this.currentLearning.thumbnail = undefined;
        this.currentLearning.thumbnailTouched = false;
        this.currentLearning.picture = undefined;
        this.currentLearning.pictureTouched = false;
        this.currentLearning.solutionPictureThumbnail = undefined;
        this.currentLearning.solutionPictureThumbnailTouched = false;
        this.currentLearning.solutionPicture = undefined;
        this.currentLearning.solutionPictureTouched = false;
        this.richContent = "";
        this.currentSlideIndex = undefined;
        this.cdr.markForCheck();
    }

    public saveSlide() {
        if (this.currentCategoryId !== LearningCategoryEnum.Learning) {
            if (this.currentSlide) {
                this.currentSlide.category = this.currentCategoryId;
                this.currentSlide.richContent = this.richContent;
                if (!(this.currentPatientCase.slides instanceof Array)) this.currentPatientCase.slides = [];
                if (this.currentSlideIndex >= 0) {
                    this.currentPatientCase.slides[this.currentSlideIndex] = this.currentSlide;
                } else {
                    this.currentPatientCase.slides.push(this.currentSlide);
                }
            }
        } else {
            if (this.currentLearning) {
                this.currentLearning.category = LearningCategoryEnum.Learning;
                this.currentLearning.solutionRichContent = this.richContent;

                if (!(this.currentPatientCase.slides instanceof Array)) this.currentPatientCase.slides = [];
                if (this.currentSlideIndex >= 0) {
                    this.currentPatientCase.slides[this.currentSlideIndex] = this.currentLearning;
                } else {
                    this.currentPatientCase.slides.push(this.currentLearning);
                }
            }
        }
        this.currentPatientCase.slides.sort(visitingDateComparator);
        this.emptyViewModelAndCollapseEditor();
    }

    showPatientCase(id: string, key: string) {
        window.open(`${environment.frontedAppUrl}cases/${id}`, "_blank").focus();
    }

    back() {
        this._location.back();
    }
}
