import { CommonModule, DatePipe } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Route, RouterModule } from "@angular/router";
import { OAuthModule } from "@impacgroup/angular-oauth-base";
import { NgSelectModule } from "@ng-select/ng-select";
import { TranslateModule } from "@ngx-translate/core";
import { DataTablesModule } from "angular-datatables";
import { CKEditorModule } from "ckeditor4-angular";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { TagInputModule } from "ngx-chips";
import { QuillModule } from "ngx-quill";
import { PicturePipe } from "src/app/pipes/PicturePipe";
import { ReferenceEditorComponent } from "./components/reference-editor/reference-editor.component";
import { ReferenceSelectorComponent } from "./components/reference-selector/reference-selector.component";
import { PatientCaseEditorComponent } from "./patientcase-editor.component";
import { PatientCasesComponent } from "./patientcases.component";
import { PatientCasesMainComponent } from "./patientcases.main.component";
import { IPatientCaseRepositoryConfig, PatientCaseRepositoryConfig, PatientCasesRepository } from "./patientcases.repository";
import { IPatientCaseServiceConfig, PatientCaseServiceConfig, PatientCasesService } from "./patientcases.service";
import { PopoverModule } from "ngx-bootstrap/popover";

@NgModule({
    declarations: [PatientCasesMainComponent, PatientCasesComponent, PatientCaseEditorComponent, PicturePipe, ReferenceEditorComponent, ReferenceSelectorComponent],
    imports: [
        BrowserAnimationsModule,
        AccordionModule,
        CKEditorModule,
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        TagInputModule,
        DataTablesModule,
        BsDropdownModule,
        BsDatepickerModule,
        CollapseModule,
        PopoverModule.forRoot(),
        ModalModule,
        NgSelectModule,
        TranslateModule,
        QuillModule,
        OAuthModule
    ],
    exports: [],
    providers: [DatePipe]
})
export class PatientCasesModule {
    static forRoot(patientCaseConfig: IPatientCaseServiceConfig & IPatientCaseRepositoryConfig): ModuleWithProviders<PatientCasesModule> {
        // returns the services as singletons throughout the app (for lazy loading purposes)
        return {
            ngModule: PatientCasesModule,
            providers: [
                PatientCasesService,
                {
                    provide: PatientCaseServiceConfig,
                    useValue: patientCaseConfig
                },
                PatientCasesRepository,
                {
                    provide: PatientCaseRepositoryConfig,
                    useValue: patientCaseConfig
                }
            ]
        };
    }

    static getRoutes(): Route[] {
        return [
            {
                path: "",
                component: PatientCasesMainComponent,
                children: [
                    {
                        path: "",
                        component: PatientCasesComponent
                    },
                    {
                        path: "add",
                        component: PatientCaseEditorComponent
                    },
                    {
                        path: ":id",
                        component: PatientCaseEditorComponent
                    }
                ]
            }
        ];
    }
}
