import { HttpClient } from "@angular/common/http";
import { Injectable, InjectionToken, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { OAuthStorageAdapter } from "@impacgroup/angular-oauth-base";
import { ApiListQueryParameter } from "@impacgroup/angular-baselib";
import { map } from "rxjs/operators";
import { instanceToPlain, plainToInstance } from "class-transformer";
import { PatientCaseDetailDTO, PatientCaseListDTO } from "./dtos/PatientCase";

export interface IPatientCaseRepositoryConfig {
    patientCasesAPI: string;
}

export const PatientCaseRepositoryConfig = new InjectionToken<IPatientCaseRepositoryConfig>("PatientCaseRepositoryConfig");

@Injectable()
export class PatientCasesRepository {
    constructor(@Inject(PatientCaseRepositoryConfig) private patientCaseRepositoryConfig: IPatientCaseRepositoryConfig, private http: HttpClient, private oauthStorageAdapter: OAuthStorageAdapter) {}

    public list(params: ApiListQueryParameter): Observable<{ list: PatientCaseListDTO[]; count: number; total: number }> {
        return this.http
            .get<any>(this.getPatientCasesServiceUrl(""), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
                params: params as any,
                observe: "response"
            })
            .pipe(
                map((result) => {
                    let count = result.body.length;
                    let total = result.body.length;
                    try {
                        count = parseInt(result.headers.get("X-Pagination-Count"), 10);
                    } catch (e) {}
                    try {
                        total = parseInt(result.headers.get("X-Total-Count"), 10);
                    } catch (e) {}

                    return {
                        list: plainToInstance(PatientCaseListDTO, result.body as any[], { excludeExtraneousValues: true }),
                        count: count,
                        total: total
                    };
                })
            );
    }

    public getById(id: string): Observable<PatientCaseDetailDTO> {
        return this.http
            .get<Object>(this.getPatientCasesServiceUrl(id), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToInstance(PatientCaseDetailDTO, result, { excludeExtraneousValues: true })));
    }

    public add(obj: PatientCaseDetailDTO, files: File[]): Observable<PatientCaseDetailDTO> {
        const formData = new FormData();
        files.forEach((file) => {
            formData.append("files", file, file.name);
        });
        formData.append("data", JSON.stringify(instanceToPlain(obj)));

        return this.http
            .post<Object>(this.getPatientCasesServiceUrl(""), formData, {
                headers: this.oauthStorageAdapter.getAuthHeadersWithoutContentType()
            })
            .pipe(map((result) => plainToInstance(PatientCaseDetailDTO, result, { excludeExtraneousValues: true })));
    }

    public update(obj: PatientCaseDetailDTO, files: File[]): Observable<PatientCaseDetailDTO> {
        const formData = new FormData();
        files.forEach((file) => {
            formData.append("files", file, file.name);
        });
        formData.append("data", JSON.stringify(instanceToPlain(obj)));

        return this.http
            .patch<Object>(this.getPatientCasesServiceUrl(obj._id), formData, {
                headers: this.oauthStorageAdapter.getAuthHeadersWithoutContentType()
            })
            .pipe(map((result) => plainToInstance(PatientCaseDetailDTO, result, { excludeExtraneousValues: true })));
    }

    public delete(id: string): Observable<void> {
        return this.http.delete<void>(this.getPatientCasesServiceUrl(id), {
            headers: this.oauthStorageAdapter.getAuthHeadersJSON()
        });
    }

    public downloadZip(id: string): Observable<{
        blob: Blob;
        contentType: string;
        filename: string;
    }> {
        return this.http
            .get(this.getPatientCasesServiceUrl(`${id}/download`), {
                headers: this.oauthStorageAdapter.getAuthHeadersWithoutContentType(),
                responseType: "blob",
                observe: "response"
            })
            .pipe(
                map((result) => {
                    const contentType = result.headers.get("Content-Type");
                    const filename = result.headers.get("Content-Disposition")?.split('"')?.[1] ?? `${id}.zip`;
                    const blob = result.body;

                    return {
                        blob,
                        contentType,
                        filename
                    };
                })
            );
    }

    public import(file: File): Observable<void> {
        const formData: FormData = new FormData();
        formData.append("file", file, file.name);

        return this.http.post<void>(this.getPatientCasesServiceUrl("import"), formData, {
            headers: this.oauthStorageAdapter.getAuthHeadersWithoutContentType()
        });
    }

    public downloadFile(
        patientCaseId: string,
        fileId: string
    ): Observable<{
        blob: Blob;
        contentType: string;
        filename: string;
    }> {
        return this.http
            .get(this.getPatientCasesServiceUrl(`${patientCaseId}/${fileId}/download`), {
                headers: this.oauthStorageAdapter.getAuthHeadersWithoutContentType(),
                responseType: "blob",
                observe: "response"
            })
            .pipe(
                map((result) => {
                    const contentType = result.headers.get("Content-Type");
                    const filename = result.headers.get("Content-Disposition")?.split('"')?.[1] ?? `unknown`;
                    const blob = result.body;

                    return {
                        blob,
                        contentType,
                        filename
                    };
                })
            );
    }

    public getPatientCasesServiceUrl(postfix: string): string {
        return this.patientCaseRepositoryConfig.patientCasesAPI + postfix;
    }
}
