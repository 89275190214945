<div class="content__inner">
    <header class="content__title">
        <h1 *ngIf="MODE === 'ADD'">{{ "patientcases.editor.add.title" | translate }}</h1>
        <h1 *ngIf="MODE === 'EDIT'">{{ "patientcases.editor.edit.title" | translate }}</h1>

        <small *ngIf="MODE === 'ADD'">{{ "patientcases.editor.add.subtitle" | translate }}</small>
        <small *ngIf="MODE === 'EDIT'">{{ "patientcases.editor.edit.subtitle" | translate }}</small>

        <div class="actions">
            <button (click)="back()" class="btn btn-secondary btn--icon-text"><i class="zmdi zmdi-arrow-back"></i> {{ "global.buttons.back" | translate }}</button>
        </div>
    </header>

    <div class="card">
        <div class="card-body">
            <h4 class="card-title">{{ "patientcases.editor.card.title" | translate }}</h4>
            <h6 class="card-subtitle">{{ "patientcases.editor.card.subtitle" | translate }}.</h6>
            <div class="form-group">
                <div class="row">
                    <div class="col-sm-4">
                        <label class="label">{{ "patientcases.props.publish" | translate }}</label>
                        <div class="input-group">
                            <div class="toggle-switch toggle-switch--blue">
                                <input type="checkbox" class="toggle-switch__checkbox" [(ngModel)]="currentPatientCase.publish" />
                                <i class="toggle-switch__helper"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4">
                        <label class="label">{{ "patientcases.props.key" | translate }}</label>
                        <div class="input-group">
                            <input type="text" class="form-control" [(ngModel)]="currentPatientCase.key" />
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <label class="label">{{ "patientcases.props.title" | translate }}</label>
                        <div class="input-group">
                            <input type="text" class="form-control" [(ngModel)]="currentPatientCase.title" />
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <label class="label">{{ "patientcases.props.subtitle" | translate }}</label>
                        <div class="input-group">
                            <input type="text" class="form-control" [(ngModel)]="currentPatientCase.subtitle" />
                            <i class="form-group__bar"></i>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <label class="label">{{ "patientcases.props.indication" | translate }}</label>
                        <div class="input-group">
                            <tag-input
                                placeholder="+ {{ 'patientcases.editor.slide.labels.placeholder.indication' | translate }}"
                                secondaryPlaceholder="{{ 'patientcases.editor.slide.labels.placeholder.enterindication' | translate }}"
                                class="w-100"
                                [(ngModel)]="currentPatientCase.indication"
                            ></tag-input>
                            <i class="form-group__bar"></i>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label class="label">{{ "patientcases.props.cancertype" | translate }}</label>
                        <div class="input-group">
                            <tag-input
                                placeholder="+ {{ 'patientcases.editor.slide.labels.placeholder.cancertype' | translate }}"
                                secondaryPlaceholder="{{ 'patientcases.editor.slide.labels.placeholder.entercancertype' | translate }}"
                                class="w-100"
                                [(ngModel)]="currentPatientCase.cancerType"
                            ></tag-input>
                            <i class="form-group__bar"></i>
                        </div>
                    </div>
                </div>
                <div class="flex-row end">
                    <div class="col-sm-4">
                        <label class="label">{{ "patientcases.props.author" | translate }}</label>
                        <div class="input-group">
                            <tag-input
                                placeholder="+ {{ 'patientcases.editor.slide.labels.placeholder.author' | translate }}"
                                secondaryPlaceholder="{{ 'patientcases.editor.slide.labels.placeholder.enterauthor' | translate }}"
                                class="w-100"
                                [(ngModel)]="currentPatientCase.author"
                            ></tag-input>
                            <i class="form-group__bar"></i>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <label class="label">{{ "patientcases.props.gender" | translate }}</label>
                        <div class="input-group">
                            <ng-select [items]="genderLabels" placeholder="{{ 'patientcases.editor.slide.labels.placeholder.selectgender' | translate }}" class="w-100" [(ngModel)]="currentPatientCase.gender"> </ng-select>
                            <i class="form-group__bar"></i>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <label class="label">{{ "patientcases.props.age" | translate }}</label>
                        <div class="input-group">
                            <input type="number" class="form-control" [(ngModel)]="currentPatientCase.age" />
                            <i class="form-group__bar"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr />

    <!-- All patient case slides -->
    <div *ngIf="currentPatientCase?.slides?.length > 0" class="card">
        <div class="card-body">
            <h4 class="card-title">{{ "patientcases.editor.slide.labels.createdslides" | translate }}</h4>
            <accordion class="accordion" [closeOthers]="'true'">
                <accordion-group *ngFor="let slide of currentPatientCase.slides; let slideIndex = index" [heading]="(slide.visitingDate | date: 'dd.MM.yyyy') + ' - ' + slide.category + ' - ' + slide.title">
                    <!-- Learnings-->
                    <div *ngIf="slide.category === 'Learning'">
                        <table>
                            <col style="width: 20%" />
                            <col style="width: 80%" />
                            <tr>
                                <td class="bold-label fit">{{ "patientcases.editor.slide.labels.highlight" | translate }}</td>
                                <td><i *ngIf="slide.highlight" class="zmdi zmdi-check-circle icon success"></i><i *ngIf="!slide.highlight" class="zmdi zmdi-close-circle icon danger"></i></td>
                            </tr>
                            <tr>
                                <td class="bold-label fit">{{ "patientcases.editor.slide.labels.showCategoryPicture" | translate }}</td>
                                <td><i *ngIf="slide.showCategoryPicture" class="zmdi zmdi-check-circle icon success"></i><i *ngIf="!slide.showCategoryPicture" class="zmdi zmdi-close-circle icon danger"></i></td>
                            </tr>

                            <tr>
                                <td class="bold-label fit">{{ "patientcases.editor.slide.labels.visitingdate" | translate }}</td>
                                <td>{{ slide.visitingDate | date: "dd.MM.yyyy" }}</td>
                            </tr>
                            <tr>
                                <td class="bold-label fit">{{ "patientcases.editor.slide.labels.title" | translate }}</td>
                                <td><span [innerHTML]="slide.title"></span></td>
                            </tr>
                            <tr>
                                <td class="bold-label fit">{{ "patientcases.editor.slide.labels.calltoaction1" | translate }}</td>
                                <td><span [innerHTML]="slide.callToAction1"></span></td>
                            </tr>
                            <tr *ngIf="slide.type === 'Drawing'">
                                <td class="bold-label fit">{{ "patientcases.editor.slide.labels.calltoaction1" | translate }}</td>
                                <td><span [innerHTML]="slide.callToAction2"></span></td>
                            </tr>
                            <tr>
                                <td class="bold-label fit">{{ "patientcases.editor.slide.labels.solutionrichcontent" | translate }}</td>
                            </tr>
                        </table>
                        <span class="w-100" [innerHTML]="slide.solutionRichContent"></span>
                        <div *ngIf="slide.type !== 'Drawing'" class="flex-column" style="gap: 16px">
                            <label class="bold-label fit">{{ "patientcases.editor.slide.labels.answers" | translate }}</label>
                            <ol>
                                <li class="fit" *ngFor="let answer of slide.answers">
                                    <span [innerHTML]="answer.title"></span><span *ngIf="answer.isCorrect">&nbsp;<i class="zmdi zmdi-check-circle icon success"></i></span>
                                </li>
                            </ol>
                        </div>
                        <ng-container *ngIf="slide.type === 'Drawing'">
                            <p class="panel-title">{{ "patientcases.editor.slide.labels.pictures" | translate }}</p>
                            <div class="flex-row mx-0" style="gap: 16px">
                                <div class="flex-column picture-preview">
                                    <img *ngIf="slide.thumbnail" [src]="slide.thumbnail | async | PicturePipe | async" class="picture" />
                                    <span class="text-muted">{{ "patientcases.editor.slide.labels.drawingthumbnail" | translate }}</span>
                                </div>
                                <div class="flex-column picture-preview">
                                    <img *ngIf="slide.picture" [src]="slide.picture | async | PicturePipe | async" class="picture" />
                                    <span class="text-muted">{{ "patientcases.editor.slide.labels.drawingpicture" | translate }}</span>
                                </div>
                                <div class="flex-column picture-preview">
                                    <img *ngIf="slide.solutionPictureThumbnail" [src]="slide.solutionPictureThumbnail | async | PicturePipe | async" class="picture" />
                                    <span class="text-muted">{{ "patientcases.editor.slide.labels.solutionthumbnail" | translate }}</span>
                                </div>
                                <div class="flex-column picture-preview">
                                    <img *ngIf="slide.solutionPicture" [src]="slide.solutionPicture | async | PicturePipe | async" class="picture" />
                                    <span class="text-muted">{{ "patientcases.editor.slide.labels.solutionpicture" | translate }}</span>
                                </div>
                            </div>
                        </ng-container>
                        <div class="flex-row mx-1 end">
                            <button (click)="editSlide(slideIndex, slideEditorModal); slideEditorModal.show()" class="btn btn-success btn--icon" style="margin-left: 25px"><i class="zmdi zmdi-edit"></i></button>
                            <button (click)="removeSlide(slideIndex)" class="btn btn-danger btn--icon" style="margin-left: 25px"><i class="zmdi zmdi-delete"></i></button>
                        </div>
                    </div>

                    <!-- Regular slides -->
                    <div class="flex-column slide-box" *ngIf="slide.category !== 'Learning'">
                        <table>
                            <col style="width: 20%" />
                            <col style="width: 80%" />
                            <tr>
                                <td class="bold-label fit">{{ "patientcases.editor.slide.labels.highlight" | translate }}</td>
                                <td><i *ngIf="slide.highlight" class="zmdi zmdi-check-circle icon success"></i><i *ngIf="!slide.highlight" class="zmdi zmdi-close-circle icon danger"></i></td>
                            </tr>
                            <tr>
                                <td class="bold-label fit">{{ "patientcases.editor.slide.labels.showCategoryPicture" | translate }}</td>
                                <td><i *ngIf="slide.showCategoryPicture" class="zmdi zmdi-check-circle icon success"></i><i *ngIf="!slide.showCategoryPicture" class="zmdi zmdi-close-circle icon danger"></i></td>
                            </tr>
                            <tr>
                                <td class="bold-label fit">{{ "patientcases.editor.slide.labels.visitingdate" | translate }}</td>
                                <td>{{ slide.visitingDate | date: "dd.MM.yyyy" }}</td>
                            </tr>
                            <tr>
                                <td class="bold-label fit">{{ "patientcases.editor.slide.labels.title" | translate }}</td>
                                <td><span [innerHTML]="slide.title"></span></td>
                            </tr>
                            <tr>
                                <td class="bold-label fit">{{ "patientcases.editor.slide.labels.shorttitle" | translate }}</td>
                                <td><span [innerHTML]="slide.shortTitle"></span></td>
                            </tr>
                            <tr>
                                <td class="bold-label fit">{{ "patientcases.editor.slide.labels.subheading" | translate }}</td>
                                <td><span [innerHTML]="slide.subheading"></span></td>
                            </tr>
                            <tr>
                                <td class="bold-label fit">{{ "patientcases.editor.slide.labels.richcontent" | translate }}</td>
                            </tr>
                        </table>
                        <p class="w-100" [innerHTML]="slide.richContent"></p>
                        <ng-container *ngIf="slide.pictures.length > 0">
                            <p class="panel-title">{{ "patientcases.editor.slide.labels.pictures" | translate }}</p>
                            <div class="flex-row mx-1" style="gap: 16px">
                                <div class="flex-column col-sm-2 pl-0" *ngFor="let picture of slide.pictures">
                                    <img *ngIf="picture.file" [src]="picture.file | async | PicturePipe | async" style="max-height: 180px; max-width: 100%" />
                                    <p style="text-align: center" [innerHTML]="picture.title"></p>
                                </div>
                            </div>
                        </ng-container>

                        <table>
                            <col style="width: 20%" />
                            <col style="width: 80%" />
                            <tr>
                                <td class="bold-label fit">{{ "patientcases.editor.slide.labels.disclaimer" | translate }}</td>
                                <td><span [innerHTML]="slide.disclaimer"></span></td>
                            </tr>
                        </table>

                        <div class="flex-row mx-1 end">
                            <button (click)="editSlide(slideIndex, slideEditorModal); slideEditorModal.show()" class="btn btn-success btn--icon" style="margin-left: 25px"><i class="zmdi zmdi-edit"></i></button>
                            <button (click)="removeSlide(slideIndex)" class="btn btn-danger btn--icon" style="margin-left: 25px"><i class="zmdi zmdi-delete"></i></button>
                        </div>
                    </div>
                </accordion-group>
            </accordion>
        </div>
    </div>

    <div *ngIf="currentPatientCase?.references?.length > 0" class="card">
        <div class="card-body">
            <h4 class="card-title">{{ "patientcases.editor.slide.labels.createdreferences" | translate }}</h4>
            <accordion class="accordion" [closeOthers]="'true'">
                <accordion-group *ngFor="let reference of currentPatientCase.references; let refIndex = index" [heading]="reference.id">
                    <p [innerHTML]="reference.richContent"></p>
                    <div class="flex-row mx-1 end">
                        <button (click)="editReference(refIndex)" class="btn btn-success btn--icon" style="margin-left: 25px"><i class="zmdi zmdi-edit"></i></button>
                        <button (click)="removeReference(refIndex)" class="btn btn-danger btn--icon" style="margin-left: 25px"><i class="zmdi zmdi-delete"></i></button>
                    </div>
                </accordion-group>
            </accordion>
        </div>
    </div>

    <!-- Slide Editor -->

    <div class="modal fade" bsModal #slideEditorModal="bs-modal" [config]="{ backdrop: 'static' }" role="dialog" aria-labelledby="dialog-static-name">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">{{ currentSlide?._id || currentLearning?._id ? ("patientcases.editor.slide.labels.edit" | translate) : ("patientcases.editor.slide.labels.create" | translate) }}</h4>
                    <button type="button" class="close pull-right m-0 p-0" aria-label="Close" (click)="slideEditorModal.hide()">
                        <span aria-hidden="true" class="visually-hidden">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row" *ngIf="currentCategoryId === 'Learning'">
                        <div class="col-sm-2">
                            <label>{{ "patientcases.editor.slide.labels.highlight" | translate }}</label>
                            <div class="form-group">
                                <div class="toggle-switch toggle-switch--blue">
                                    <input type="checkbox" class="toggle-switch__checkbox" [(ngModel)]="currentLearning.highlight" />
                                    <i class="toggle-switch__helper"></i>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-3" *ngIf="currentCategoryId === 'Learning'">
                            <label>{{ "patientcases.editor.slide.labels.showCategoryPicture" | translate }}</label>
                            <div class="form-group">
                                <div class="toggle-switch toggle-switch--blue">
                                    <input type="checkbox" class="toggle-switch__checkbox" [(ngModel)]="currentLearning.showCategoryPicture" />
                                    <i class="toggle-switch__helper"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex-row">
                        <div class="col-sm-2">
                            <label>{{ "patientcases.editor.slide.labels.category" | translate }}</label>
                            <div class="form-group">
                                <ng-select [items]="categoryLabels" placeholder="{{ 'patientcases.editor.slide.labels.placeholder.selectcategory' | translate }}" [(ngModel)]="currentCategoryId"> </ng-select>
                            </div>
                        </div>
                        <div class="col-sm-3" *ngIf="currentCategoryId === 'Learning'">
                            <label>{{ "patientcases.editor.slide.labels.learningtype" | translate }}</label>
                            <div class="row learning-type-chooser">
                                <div class="radio" *ngFor="let learningType of learningTypeLabels">
                                    <input type="radio" name="learningType" [id]="learningType" [value]="learningType" [(ngModel)]="currentLearning.type" [checked]="learningType === 'MultipleChoice'" />
                                    <label class="radio__label" [for]="learningType">{{ learningType }}</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-2" *ngIf="currentCategoryId === 'Learning'">
                            <label>{{ "patientcases.editor.slide.labels.affiliatedcategory" | translate }}</label>
                            <div class="form-group">
                                <ng-select [items]="affiliatedCategoryLabels" placeholder="{{ 'patientcases.editor.slide.labels.placeholder.selectcategory' | translate }}" [(ngModel)]="currentLearning.affiliatedCategoryId"> </ng-select>
                            </div>
                        </div>
                        <div class="col-sm-3" *ngIf="currentCategoryId === 'Learning'">
                            <label>{{ "patientcases.editor.slide.labels.visitingdate" | translate }}</label>
                            <div class="form-group">
                                <input
                                    type="text"
                                    placeholder="{{ 'patientcases.editor.slide.labels.placeholder.pickdate' | translate }}"
                                    class="form-control mt-1"
                                    bsDatepicker
                                    [bsConfig]="datePickerConfig"
                                    [(ngModel)]="currentLearning.visitingDate"
                                />
                                <i class="form-group__bar"></i>
                            </div>
                        </div>
                        <div class="col-sm-3" *ngIf="currentCategoryId !== 'Learning'">
                            <label>{{ "patientcases.editor.slide.labels.visitingdate" | translate }}</label>
                            <div class="form-group">
                                <input
                                    type="text"
                                    placeholder="{{ 'patientcases.editor.slide.labels.placeholder.pickdate' | translate }}"
                                    class="form-control"
                                    bsDatepicker
                                    [bsConfig]="datePickerConfig"
                                    [(ngModel)]="currentSlide.visitingDate"
                                />
                                <i class="form-group__bar"></i>
                            </div>
                        </div>

                        <div class="col-sm-2" *ngIf="currentCategoryId !== 'Learning'">
                            <label>{{ "patientcases.editor.slide.labels.highlight" | translate }}</label>
                            <div class="form-group">
                                <div class="toggle-switch toggle-switch--blue">
                                    <input type="checkbox" class="toggle-switch__checkbox" [(ngModel)]="currentSlide.highlight" />
                                    <i class="toggle-switch__helper"></i>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-3" *ngIf="currentCategoryId !== 'Learning'">
                            <label>{{ "patientcases.editor.slide.labels.showCategoryPicture" | translate }}</label>
                            <div class="form-group">
                                <div class="toggle-switch toggle-switch--blue">
                                    <input type="checkbox" class="toggle-switch__checkbox" [(ngModel)]="currentSlide.showCategoryPicture" />
                                    <i class="toggle-switch__helper"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div *ngIf="currentCategoryId === 'Learning'">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <label
                                            >{{ "patientcases.editor.slide.labels.title" | translate }}
                                            <i class="zmdi zmdi-info-outline" triggers="mouseenter" [outsideClick]="true" [popoverTitle]="htmlContentInfoTitle" [popover]="htmlContentInfoText"></i>
                                        </label>
                                        <div class="form-group">
                                            <input type="text" class="form-control" [(ngModel)]="currentLearning.title" />
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <label
                                            >{{ "patientcases.editor.slide.labels.calltoaction1" | translate }}
                                            <i class="zmdi zmdi-info-outline" triggers="mouseenter" [outsideClick]="true" [popoverTitle]="htmlContentInfoTitle" [popover]="htmlContentInfoText"></i>
                                        </label>
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="{{ 'patientcases.editor.slide.labels.placeholder.calltoaction1' | translate }}" [(ngModel)]="currentLearning.callToAction1" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <label>{{ "patientcases.editor.slide.labels.solutionrichcontent" | translate }}</label>
                                        <div class="form-group">
                                            <ng-container *ngTemplateOutlet="cke"></ng-container>
                                        </div>
                                    </div>
                                </div>
                                <div [ngSwitch]="currentLearning.type">
                                    <div *ngSwitchCase="'MultipleChoice'">
                                        <div class="flex flex-column mb-2">
                                            <div class="flex flex-column flex-lg-row m-0 justify-content-between">
                                                <label
                                                    >{{ "patientcases.editor.slide.labels.answers" | translate }}
                                                    <i class="zmdi zmdi-info-outline" triggers="mouseenter" [outsideClick]="true" [popoverTitle]="htmlContentInfoTitle" [popover]="htmlContentInfoText"></i>
                                                </label>

                                                <div class="flex flex-row ml-0 ml-lg-3 mr-0">
                                                    <label class="mr-2 mr-lg-0">{{ "patientcases.editor.slide.labels.hideShowVotesButton" | translate }}</label>
                                                    <div class="form-group ml-lg-3 mb-0">
                                                        <div class="toggle-switch toggle-switch--blue">
                                                            <input type="checkbox" class="toggle-switch__checkbox" [(ngModel)]="currentLearning.hideShowVotesButton" />
                                                            <i class="toggle-switch__helper"></i>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="flex flex-row ml-0 ml-lg-3 mr-0">
                                                    <label class="mr-2 mr-lg-0">{{ "patientcases.editor.slide.labels.hasOnlyOneValidAnswer" | translate }}</label>
                                                    <div class="form-group ml-3 mb-0">
                                                        <div class="toggle-switch toggle-switch--blue">
                                                            <input type="checkbox" class="toggle-switch__checkbox" [(ngModel)]="currentLearning.hasOnlyOneValidAnswer" />
                                                            <i class="toggle-switch__helper"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div id="answers">
                                            <div class="answer" *ngFor="let answer of currentLearning.answers; let idx = index">
                                                <div class="form-group row">
                                                    <input type="text" class="form-control" placeholder="{{ 'patientcases.editor.slide.labels.placeholder.answerhint' | translate }}" [(ngModel)]="answer.title" />
                                                    <div class="checkbox">
                                                        <input type="checkbox" [id]="'checkbox' + idx" [(ngModel)]="answer.isCorrect" />
                                                        <label class="checkbox__label" [for]="'checkbox' + idx">{{ "patientcases.editor.slide.labels.answercorrect" | translate }}</label>
                                                    </div>
                                                    <button (click)="removeAnswer(idx)" class="btn btn-danger btn--icon remove" type="button">
                                                        <i class="zmdi zmdi-delete"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <button type="button" class="btn btn-primary" (click)="addAnswer()">{{ "patientcases.editor.slide.labels.createanswer" | translate }}</button>
                                    </div>
                                    <div *ngSwitchCase="'Drawing'">
                                        <label
                                            >{{ "patientcases.editor.slide.labels.calltoaction2" | translate }}
                                            <i class="zmdi zmdi-info-outline" triggers="mouseenter" [outsideClick]="true" [popoverTitle]="htmlContentInfoTitle" [popover]="htmlContentInfoText"></i>
                                        </label>
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="{{ 'patientcases.editor.slide.labels.placeholder.calltoaction2' | translate }}" [(ngModel)]="currentLearning.callToAction2" />
                                        </div>
                                        <label>{{ "patientcases.editor.slide.labels.drawingpicture" | translate }}</label>
                                        <div class="row">
                                            <div class="col-sm-2">
                                                <div class="form-group column">
                                                    <img class="w-100 mb-2" *ngIf="currentLearning.thumbnail" [src]="currentLearning.thumbnail | async | PicturePipe | async" />
                                                    <button (click)="darwingThumbnailInput.click()" class="btn btn-success btn-block btn--icon-text mt-1" type="button">
                                                        <i *ngIf="currentLearning.thumbnail" class="zmdi zmdi-edit"></i><i *ngIf="!currentLearning.thumbnail" class="zmdi zmdi-collection-plus"></i>
                                                        {{ currentLearning.thumbnail ? "Edit drawing thumb" : "Add drawing thumb" }}
                                                        <input #darwingThumbnailInput type="file" multiple="true" style="display: none" class="form-control" (change)="handleFileInput($event.target.files, 'draw-thumbnail')" />
                                                    </button>
                                                </div>
                                                <i class="form-group__bar"></i>
                                            </div>
                                            <div class="col-sm-2">
                                                <div class="form-group column">
                                                    <img class="w-100 mb-2" *ngIf="currentLearning.picture" [src]="currentLearning.picture | async | PicturePipe | async" />
                                                    <button (click)="darwingPictureInput.click()" class="btn btn-success btn-block btn--icon-text mt-1" type="button">
                                                        <i class="zmdi zmdi-collection-plus"></i> {{ currentLearning.picture ? "Edit drawing picture" : "Add drawing picture" }}
                                                        <input #darwingPictureInput type="file" multiple="true" style="display: none" class="form-control" (change)="handleFileInput($event.target.files, 'draw')" />
                                                    </button>
                                                </div>
                                                <i class="form-group__bar"></i>
                                            </div>
                                        </div>
                                        <label>{{ "patientcases.editor.slide.labels.solutionpicture" | translate }}</label>
                                        <div class="row">
                                            <div class="col-sm-2">
                                                <div class="form-group column">
                                                    <img class="w-100 mb-2" *ngIf="currentLearning.solutionPictureThumbnail" [src]="currentLearning.solutionPictureThumbnail | async | PicturePipe | async" />
                                                    <button (click)="solutionThumbnailInput.click()" class="btn btn-success btn-block btn--icon-text mt-1" type="button">
                                                        <i class="zmdi zmdi-collection-plus"></i> {{ currentLearning.solutionPictureThumbnail ? "Edit solution thumb" : "Add solution thumb" }}
                                                        <input #solutionThumbnailInput type="file" multiple="true" style="display: none" class="form-control" (change)="handleFileInput($event.target.files, 'solution-thumbnail')" />
                                                    </button>
                                                </div>
                                                <i class="form-group__bar"></i>
                                            </div>
                                            <div class="col-sm-2">
                                                <div class="form-group column">
                                                    <img class="w-100 mb-2" *ngIf="currentLearning.solutionPicture" [src]="currentLearning.solutionPicture | async | PicturePipe | async" />
                                                    <button (click)="solutionPictureInput.click()" class="btn btn-success btn-block btn--icon-text mt-1" type="button">
                                                        <i class="zmdi zmdi-collection-plus"></i> {{ currentLearning.solutionPicture ? "Edit solution picture" : "Add solution picture" }}
                                                        <input #solutionPictureInput type="file" multiple="true" style="display: none" class="form-control" (change)="handleFileInput($event.target.files, 'solution')" />
                                                    </button>
                                                </div>
                                                <i class="form-group__bar"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="currentCategoryId !== 'Learning'">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <label
                                            >{{ "patientcases.editor.slide.labels.title" | translate }}
                                            <i class="zmdi zmdi-info-outline" triggers="mouseenter" [outsideClick]="true" [popoverTitle]="htmlContentInfoTitle" [popover]="htmlContentInfoText"></i>
                                        </label>
                                        <div class="form-group">
                                            <input type="text" class="form-control" [(ngModel)]="currentSlide.title" />
                                        </div>
                                    </div>
                                </div>
                                <ng-container *ngIf="currentCategoryId && currentCategoryId !== 'Learning'">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <label
                                                >{{ "patientcases.editor.slide.labels.shorttitle" | translate }}
                                                <i class="zmdi zmdi-info-outline" triggers="mouseenter" [outsideClick]="true" [popoverTitle]="htmlContentInfoTitle" [popover]="htmlContentInfoText"></i>
                                            </label>
                                            <div class="form-group">
                                                <input type="text" class="form-control" [(ngModel)]="currentSlide.shortTitle" />
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <label
                                                >{{ "patientcases.editor.slide.labels.subheading" | translate }}
                                                <i class="zmdi zmdi-info-outline" triggers="mouseenter" [outsideClick]="true" [popoverTitle]="htmlContentInfoTitle" [popover]="htmlContentInfoText"></i>
                                            </label>
                                            <div class="form-group">
                                                <input type="text" class="form-control" [(ngModel)]="currentSlide.subheading" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <label>{{ "patientcases.editor.slide.labels.richcontent" | translate }}</label>
                                            <div class="form-group">
                                                <ng-container *ngTemplateOutlet="cke"></ng-container>
                                            </div>
                                        </div>
                                    </div>
                                    <label>{{ "patientcases.editor.slide.labels.files" | translate }}</label>
                                    <div class="row mx-1 uploaded-files">
                                        <div *ngFor="let picture of currentSlide.pictures; let idx = index" class="col-lg-6 pl-0 flex-column end">
                                            <button *ngIf="picture" (click)="removePicture(idx)" class="btn btn-danger btn--icon remove" type="button">
                                                <i class="zmdi zmdi-delete"></i>
                                            </button>
                                            <div class="flex-row mx-1">
                                                <div class="flex-column end">
                                                    <img *ngIf="picture.thumbnail" [src]="picture.thumbnail | async | PicturePipe | async" class="picture" />
                                                    <button (click)="thumbnailFileInput.click()" class="btn btn-success btn--icon-text mt-1" type="button">
                                                        <i *ngIf="!picture.thumbnail" class="zmdi zmdi-collection-plus"></i>
                                                        <i *ngIf="picture.thumbnail" class="zmdi zmdi-edit"></i> {{ "patientcases.editor.slide.labels.thumbnail" | translate }}
                                                        <input #thumbnailFileInput type="file" multiple="true" style="display: none" class="form-control" (change)="handleFileInput($event.target.files, 'picture-thumbnail', idx)" />
                                                    </button>
                                                </div>
                                                <div class="flex-column end ml-2">
                                                    <img *ngIf="picture.file" [src]="picture.file | async | PicturePipe | async" class="picture" />
                                                    <button (click)="pictureFileInput.click()" class="btn btn-success btn--icon-text mt-1" type="button">
                                                        <i *ngIf="!picture.file" class="zmdi zmdi-collection-plus"></i>
                                                        <i *ngIf="picture.file" class="zmdi zmdi-edit"></i> {{ "patientcases.editor.slide.labels.file" | translate }}
                                                        <input #pictureFileInput type="file" multiple="true" style="display: none" class="form-control" (change)="handleFileInput($event.target.files, 'picture', idx)" />
                                                    </button>
                                                </div>
                                            </div>
                                            <label class="label"
                                                >{{ "patientcases.editor.slide.labels.title" | translate }}
                                                <i class="zmdi zmdi-info-outline" triggers="mouseenter" [outsideClick]="true" [popoverTitle]="htmlContentInfoTitle" [popover]="htmlContentInfoText"></i>
                                            </label>
                                            <div class="input-group">
                                                <input type="text" class="form-control" placeholder="{{ 'patientcases.editor.slide.labels.placeholder.picturetitle' | translate }}" [(ngModel)]="picture.title" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <button (click)="addNewPicturePair()" class="btn btn-success btn--icon-text mt-1" type="button">
                                            <i class="zmdi zmdi-collection-plus"></i> {{ "patientcases.editor.slide.labels.addpicture" | translate }}
                                        </button>
                                        <i class="form-group__bar"></i>
                                    </div>

                                    <div class="row">
                                        <div class="col-12">
                                            <label
                                                >{{ "patientcases.editor.slide.labels.disclaimer" | translate }}
                                                <i class="zmdi zmdi-info-outline" triggers="mouseenter" [outsideClick]="true" [popoverTitle]="htmlContentInfoTitle" [popover]="htmlContentInfoText"></i>
                                            </label>
                                            <div class="form-group">
                                                <input type="text" class="form-control" [(ngModel)]="currentSlide.disclaimer" />
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>

                            <div class="actions">
                                <button (click)="saveSlide(); slideEditorModal.hide()" class="btn btn-success btn--icon-text" [disabled]="!currentSlide.visitingDate && !currentLearning.visitingDate">
                                    <i class="zmdi zmdi-check-square"></i> {{ "patientcases.editor.slide.labels.save" | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Slide Editor -->
    <div class="row">
        <div class="col-sm-12">
            <button type="button" class="btn btn-primary" (click)="emptyViewModelAndCollapseEditor(); slideEditorModal.show()">{{ "patientcases.editor.slide.labels.create" | translate }}</button>
            <button type="button" class="btn btn-primary ml-3" (click)="editReference(undefined)">{{ "patientcases.editor.slide.labels.createreference" | translate }}</button>

            <button (click)="savePatientCase()" class="float-right btn btn-success btn--icon-text"><i class="zmdi zmdi-check"></i> {{ "patientcases.editor.save" | translate }}</button>
        </div>
    </div>
</div>

<ng-template #cke>
    <ckeditor #ckEditorSlide="ngModel" name="ckEditorSlide" [(ngModel)]="richContent" [config]="ckeConfig" (ready)="onReady($event)"> </ckeditor>
    <button class="btn btn-secondary" (click)="selectReference($event)">{{ "patientcases.editor.slide.labels.addreference" | translate }}</button>
</ng-template>
