<div class="modal-content">
    <div class="modal-header">
        <h4 class="modal-title pull-left">Reference selector</h4>
    </div>
    <div class="modal-body">
        <div class="form-group mb-0 pb-0">
            <ng-select [items]="caseReferences" placeholder="Select a reference" class="w-100" [(ngModel)]="selectedReferenceId"> </ng-select>
        </div>
    </div>
    <div class="modal-footer"><button type="button" class="btn btn-link" (click)="clickOk()">OK</button><button type="button" class="btn btn-link" (click)="close()">Close</button></div>
</div>
