<header class="header">
    <app-navigation-trigger></app-navigation-trigger>

    <app-logo></app-logo>

    <ul class="top-nav">
        <li class="hidden-xs-down dropdown" dropdown>
            <a [routerLink]="[]" dropdownToggle><i class="zmdi zmdi-more-vert"></i></a>

            <div *dropdownMenu class="dropdown-menu dropdown-menu-right">
                <div class="dropdown-item theme-switch">
                    {{ "global.themeswitch" | translate }}

                    <div class="btn-group btn-group--colors">
                        <label *ngFor="let theme of maThemes" [ngClass]="['btn bg-' + theme]" [(ngModel)]="maCurrentTheme" [btnRadio]="theme" (click)="setMaTheme()"></label>
                    </div>
                </div>
                <a href="#" class="dropdown-item" (click)="logout()">{{ "global.logout" | translate }}</a>
            </div>
        </li>
    </ul>
</header>
