import { Component, EventEmitter, Output } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
    selector: "app-reference-selector",
    templateUrl: "./reference-selector.component.html",
    styleUrls: ["./reference-selector.component.scss"]
})
export class ReferenceSelectorComponent {
    @Output()
    public action = new EventEmitter();

    public caseReferences: string[];
    public selectedReferenceId: string;

    constructor(private modalRef: BsModalRef) {}

    public clickOk() {
        this.action.emit(this.selectedReferenceId);
        this.close();
    }

    public close(): void {
        this.modalRef.hide();
    }
}
