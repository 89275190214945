import { Pipe, PipeTransform } from "@angular/core";
import { from, Observable } from "rxjs";
import { fileToBase64 } from "../utils/fileutils";

@Pipe({ name: "PicturePipe" })
export class PicturePipe implements PipeTransform {
    transform(value: File): Observable<string | ArrayBuffer> {
        if (!value) {
            return from("");
        }
        return from(fileToBase64(value));
    }
}
