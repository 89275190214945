<footer class="footer hidden-xs-down">
    <p>
        {{ "global.footer.copyright" | translate }}<br />
        Version: {{ version }} | Environment: {{ environmentName }}
    </p>

    <!--
  <ul class="nav footer__nav">
    <a class="nav-link" [routerLink]="">Homepage</a>

    <a class="nav-link" [routerLink]="">Company</a>

    <a class="nav-link" [routerLink]="">Support</a>

    <a class="nav-link" [routerLink]="">News</a>

    <a class="nav-link" [routerLink]="">Contacts</a>
  </ul>
  -->
</footer>
