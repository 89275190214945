import { Injectable, InjectionToken, Inject } from "@angular/core";
import * as moment from "moment";
import { from, lastValueFrom, Observable } from "rxjs";
import { ApiListQueryParameter, BaseDatatableStateSaveMode } from "@impacgroup/angular-baselib";
import { map, switchMap } from "rxjs/operators";
import { PatientCasesRepository } from "./patientcases.repository";
import { LearningCategoryEnum, LearningModel, PatientCaseDetailModel, PatientCaseListModel } from "./viewmodels/PatientCase";
import { CategoryDTO, PatientCaseDetailDTO } from "./dtos/PatientCase";
import { plainToInstance } from "class-transformer";

export const visitingDateComparator = (first: { visitingDate: Date }, second: { visitingDate: Date }) => 0 - (first.visitingDate > second.visitingDate ? -1 : 1);

export interface IPatientCaseServiceConfig {
    utcDateFormat: string;
    datatableStateSaveMode: BaseDatatableStateSaveMode;
}

export const PatientCaseServiceConfig = new InjectionToken<IPatientCaseServiceConfig>("PatientCaseServiceConfig");

@Injectable()
export class PatientCasesService {
    public UTCDATEFORMAT: string = "";
    public datatableStateSaveMode: BaseDatatableStateSaveMode;

    constructor(@Inject(PatientCaseServiceConfig) private patientCaseServiceConfig: IPatientCaseServiceConfig, private patientCasesRepository: PatientCasesRepository) {
        this.UTCDATEFORMAT = this.patientCaseServiceConfig.utcDateFormat;
        this.datatableStateSaveMode = this.patientCaseServiceConfig.datatableStateSaveMode;
    }

    public list(params: ApiListQueryParameter): Observable<{ list: PatientCaseListModel[]; count: number; total: number }> {
        return this.patientCasesRepository.list(params).pipe(
            map((result) => {
                return {
                    list: result.list.map((patientCaseDTO) => {
                        return {
                            ...patientCaseDTO
                        };
                    }),
                    count: result.count,
                    total: result.total
                };
            })
        );
    }

    public getById(id: string): Observable<PatientCaseDetailModel> {
        return this.patientCasesRepository.getById(id).pipe(map((result) => this.dtoToModelConverter(result)));
    }

    public add(obj: PatientCaseDetailModel): Observable<PatientCaseDetailModel> {
        return from(this.modelToDtoConverter(obj)).pipe(
            switchMap((result) => {
                return this.patientCasesRepository.add(result.dto, result.files).pipe(map((result) => this.dtoToModelConverter(result)));
            })
        );
    }

    public update(obj: PatientCaseDetailModel): Observable<PatientCaseDetailModel> {
        return from(this.modelToDtoConverter(obj)).pipe(
            switchMap((result) => {
                return this.patientCasesRepository.update(result.dto, result.files).pipe(map((result) => this.dtoToModelConverter(result)));
            })
        );
    }

    public delete(id: string): Observable<void> {
        return this.patientCasesRepository.delete(id);
    }

    public downloadZip(id: string): Observable<{
        blob: Blob;
        contentType: string;
        filename: string;
    }> {
        return this.patientCasesRepository.downloadZip(id);
    }

    public downloadFile(
        patientCaseId: string,
        fileId: string
    ): Observable<{
        blob: Blob;
        contentType: string;
        filename: string;
    }> {
        return this.patientCasesRepository.downloadFile(patientCaseId, fileId);
    }

    public import(file: File): Observable<void> {
        return this.patientCasesRepository.import(file);
    }

    private modelToDtoConverter = async (patientCase: PatientCaseDetailModel): Promise<{ dto: PatientCaseDetailDTO; files: File[] }> => {
        const files: File[] = [];
        const categories: {
            [key: string]: CategoryDTO;
        } = {};

        for (const slide of patientCase.slides) {
            if (slide.category !== LearningCategoryEnum.Learning) {
                categories[slide.category] = {
                    _id: categories[slide.category]?._id ?? (slide.categoryId ? slide.categoryId : undefined),
                    id: slide.category,
                    slides: []
                };
            }
        }
        for (const slide of patientCase.slides) {
            if (slide.category !== LearningCategoryEnum.Learning) {
                categories[slide.category].slides.push({
                    ...slide,
                    visitingDate: moment(slide.visitingDate).format("YYYY-MM-DD"),
                    pictures: await Promise.all(
                        slide.pictures.map(async (picture) => {
                            return {
                                ...picture,
                                file:
                                    picture.fileTouched && picture.file
                                        ? await (async () => {
                                              const file = await lastValueFrom(picture.file);
                                              files.push(file);
                                              return file.name;
                                          })()
                                        : picture.fileId,
                                thumbnail:
                                    picture.thumbnailTouched && picture.thumbnail
                                        ? await (async () => {
                                              const file = await lastValueFrom(picture.thumbnail);
                                              files.push(file);
                                              return file.name;
                                          })()
                                        : picture.thumbnailId
                            };
                        })
                    )
                });
            }
        }

        const obj: PatientCaseDetailDTO = {
            ...patientCase,
            indication: patientCase.indication?.map((obj) => obj.value) ?? [],
            cancerType: patientCase.cancerType?.map((obj) => obj.value) ?? [],
            author: patientCase.author?.map((obj) => obj.value) ?? [],
            categories: Object.values(categories),
            learnings: await Promise.all(
                patientCase.slides
                    .filter((slide) => slide.category === LearningCategoryEnum.Learning)
                    .map(async (learning: LearningModel) => {
                        return {
                            ...learning,
                            visitingDate: moment(learning.visitingDate).format("YYYY-MM-DD"),
                            picture:
                                learning.pictureTouched && learning.picture
                                    ? await (async () => {
                                          const file = await lastValueFrom(learning.picture);
                                          files.push(file);
                                          return file.name;
                                      })()
                                    : learning.pictureId,
                            thumbnail:
                                learning.thumbnailTouched && learning.thumbnail
                                    ? await (async () => {
                                          const file = await lastValueFrom(learning.thumbnail);
                                          files.push(file);
                                          return file.name;
                                      })()
                                    : learning.thumbnailId,
                            solutionPicture:
                                learning.solutionPictureTouched && learning.solutionPicture
                                    ? await (async () => {
                                          const file = await lastValueFrom(learning.solutionPicture);
                                          files.push(file);
                                          return file.name;
                                      })()
                                    : learning.solutionPictureId,
                            solutionPictureThumbnail:
                                learning.solutionPictureThumbnailTouched && learning.solutionPictureThumbnail
                                    ? await (async () => {
                                          const file = await lastValueFrom(learning.solutionPictureThumbnail);
                                          files.push(file);
                                          return file.name;
                                      })()
                                    : learning.solutionPictureThumbnailId
                        };
                    })
            )
        };

        const dto: PatientCaseDetailDTO = plainToInstance(PatientCaseDetailDTO, obj, { excludeExtraneousValues: true });

        return { dto, files };
    };

    private dtoToModelConverter = (patientCase: PatientCaseDetailDTO): PatientCaseDetailModel => {
        const result: PatientCaseDetailModel = {
            ...patientCase,
            indication: patientCase.indication.map((value) => {
                return {
                    display: value,
                    value
                };
            }),
            cancerType: patientCase.cancerType.map((value) => {
                return {
                    display: value,
                    value
                };
            }),
            author: patientCase.author.map((value) => {
                return {
                    display: value,
                    value
                };
            }),
            slides: [
                ...patientCase.categories.reduce((slideArray, category) => {
                    return [
                        ...category.slides.map((slide) => {
                            return {
                                ...slide,
                                category: category.id,
                                categoryId: category._id,
                                visitingDate: moment(slide.visitingDate, "YYYY-MM-DD").toDate(),
                                pictures: slide.pictures.map((picture) => {
                                    return {
                                        ...picture,
                                        fileId: picture.file,
                                        file: this.downloadFile(patientCase._id, picture.file).pipe(
                                            map((file) => {
                                                return new File([file.blob], file.filename, {
                                                    type: file.contentType
                                                });
                                            })
                                        ),
                                        fileTouched: false,
                                        thumbnailId: picture.thumbnail,
                                        thumbnail: this.downloadFile(patientCase._id, picture.thumbnail).pipe(
                                            map((file) => {
                                                return new File([file.blob], file.filename, {
                                                    type: file.contentType
                                                });
                                            })
                                        ),
                                        thumbnailTouched: false
                                    };
                                })
                            };
                        }),
                        ...slideArray
                    ];
                }, []),
                ...(patientCase.learnings
                    ? patientCase.learnings.map((learning) => {
                          return {
                              ...learning,
                              category: LearningCategoryEnum.Learning,
                              visitingDate: moment(learning.visitingDate, "YYYY-MM-DD").toDate(),
                              pictureId: learning.picture,
                              picture: this.downloadFile(patientCase._id, learning.picture).pipe(
                                  map((file) => {
                                      return new File([file.blob], file.filename, {
                                          type: file.contentType
                                      });
                                  })
                              ),
                              pictureTouched: false,
                              thumbnailId: learning.thumbnail,
                              thumbnail: this.downloadFile(patientCase._id, learning.thumbnail).pipe(
                                  map((file) => {
                                      return new File([file.blob], file.filename, {
                                          type: file.contentType
                                      });
                                  })
                              ),
                              thumbnailTouched: false,
                              solutionPictureId: learning.solutionPicture,
                              solutionPicture: this.downloadFile(patientCase._id, learning.solutionPicture).pipe(
                                  map((file) => {
                                      return new File([file.blob], file.filename, {
                                          type: file.contentType
                                      });
                                  })
                              ),
                              solutionPictureTouched: false,
                              solutionPictureThumbnailId: learning.solutionPictureThumbnail,
                              solutionPictureThumbnail: this.downloadFile(patientCase._id, learning.solutionPictureThumbnail).pipe(
                                  map((file) => {
                                      return new File([file.blob], file.filename, {
                                          type: file.contentType
                                      });
                                  })
                              ),
                              solutionPictureThumbnailTouched: false
                          };
                      })
                    : [])
            ]
        };

        result.slides.sort(visitingDateComparator);
        return result;
    };
}
