<div class="content__inner">
    <header class="content__title">
        <h1>{{ "patientcases.list.title" | translate }}</h1>
        <small>{{ "patientcases.list.subtitle" | translate }}</small>

        <div class="actions">
            <button *appHasPermission="'PATIENTCASES.ADD'" [routerLink]="['add']" class="btn btn-success btn--icon-text" style="margin-left: 25px">
                <i class="zmdi zmdi-collection-plus"></i> {{ "patientcases.list.create" | translate }}
            </button>
            <button *appHasPermission="'PATIENTCASES.UPLOAD'" (click)="fileInput.click()" class="btn btn-success btn--icon-text" style="margin-left: 25px">
                <i class="zmdi zmdi-upload"></i> {{ "patientcases.list.upload" | translate }}
                <input #fileInput type="file" (change)="uploadPatientCase($event.target.files)" style="display: none" />
            </button>
        </div>
    </header>

    <div class="card" *appHasPermission="'PATIENTCASES.LIST'">
        <div class="card-body">
            <h4 class="card-title">{{ "patientcases.list.card.title" | translate }}</h4>

            <table datatable class="table table-bordered" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr>
                        <th id="publish">{{ "patientcases.props.publish" | translate }}</th>
                        <th id="id">{{ "patientcases.props.id" | translate }}</th>
                        <th id="key">{{ "patientcases.props.key" | translate }}</th>
                        <th id="title">{{ "patientcases.props.title" | translate }}</th>
                        <th id="created">{{ "patientcases.props.created" | translate }}</th>
                        <th id="updated">{{ "patientcases.props.updated" | translate }}</th>
                        <th id="actions"></th>
                    </tr>
                </thead>
                <tbody *ngIf="rows?.length != 0">
                    <tr *ngFor="let row of rows">
                        <td>{{ row.publish ? "yes" : "no" }}</td>
                        <td>{{ row._id }}</td>
                        <td>{{ row.key }}</td>
                        <td>{{ row.title }}</td>
                        <td>{{ row.created | date: UTCDATEFORMAT:"+0" }}</td>
                        <td>{{ row.updated | date: UTCDATEFORMAT:"+0" }}</td>
                        <td class="text-right nowrap">
                            <i *appHasPermission="'PATIENTCASES.EDIT'" (click)="editPatientCase(row._id, row.key)" class="zmdi zmdi-edit zmdi-hc-fw tableIcon"></i>
                            <i (click)="showPatientCase(row._id, row.key)" class="zmdi zmdi-eye zmdi-hc-fw tableIcon"></i>
                            <span *ngIf="row.downloadable"><i (click)="downloadPatientCase(row._id)" class="zmdi zmdi-download zmdi-hc-fw tableIcon" *appHasPermission="'PATIENTCASES.DOWNLOAD'"></i></span>
                            <i (click)="deletePatientCase(row._id)" class="zmdi zmdi-delete zmdi-hc-fw tableIcon" *appHasPermission="'PATIENTCASES.DELETE'"></i>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="rows?.length == 0">
                    <tr>
                        <td colspan="6" class="no-data-available">{{ "global.datatable.empty" | translate }}</td>
                    </tr>
                </tbody>

                <tbody></tbody>
            </table>
        </div>
    </div>
</div>
