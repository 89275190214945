import { Component, EventEmitter, Output } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ReferenceModel } from "../../viewmodels/PatientCase";
import { CKEditor4 } from "ckeditor4-angular";

@Component({
    selector: "app-reference-editor",
    templateUrl: "./reference-editor.component.html",
    styleUrls: ["./reference-editor.component.scss"]
})
export class ReferenceEditorComponent {
    public ckeConfig: CKEditor4.Config = {
        extraPlugins: "divarea",
        forcePasteAsPlainText: false,
        allowedContent: true,
        removePlugins: "'exportpdf'",
        removeButtons: "",
        toolbar: [
            { name: "clipboard", items: ["Cut", "Copy", "Paste", "PasteText", "-", "Undo", "Redo"] },
            { name: "editing", items: ["Scayt"] },
            { name: "links", items: ["Link", "Unlink", "Anchor"] },
            { name: "insert", items: ["Image", "Table", "HorizontalRule", "SpecialChar"] },
            { name: "tools", items: ["Maximize"] },
            { name: "document", items: ["Source"] },
            "/",
            { name: "basicstyles", items: ["Bold", "Italic", "Strike", "-", "Subscript", "Superscript", "RemoveFormat"] },
            { name: "paragraph", items: ["NumberedList", "BulletedList", "-", "Outdent", "Indent", "-", "Blockquote"] },
            { name: "styles", items: ["Styles", "Format"] }
        ]
    };

    @Output()
    public action = new EventEmitter();

    public currentReference: ReferenceModel = {};

    constructor(private modalRef: BsModalRef) {}

    public clickOk() {
        this.action.emit(this.currentReference);
        this.close();
    }

    public close(): void {
        this.modalRef.hide();
    }
}
