<div class="user">
    <div class="user__info" (click)="userMenu = !userMenu" [class.active]="userMenu">
        <div class="user__name">{{ organization }}</div>
        <div class="user__email">{{ email }}</div>
    </div>

    <div *ngIf="userMenu" [@toggleUserMenu]="userMenu" class="user__menu">
        <a (click)="editProfile()">{{ "global.editprofile" | translate }}</a>
        <a *appHasPermission="'GLOBAL'" (click)="switch()">{{ "global.switch" | translate }}</a>
        <a (click)="logout()">{{ "global.logout" | translate }}</a>
    </div>
</div>
