import { Observable } from "rxjs";
import { CategoryEnum, Gender, LearningEnum } from "../dtos/PatientCase";

// -------------------- Model for list view -------------------- //
export type PatientCaseListModel = {
    _id?: string;
    publish?: boolean;
    key?: string;
    title?: string;
    created?: Date;
    updated?: Date;
};

// -------------------- Model for detail view -------------------- //
export enum LearningCategoryEnum {
    Learning = "Learning"
}

export type AnswerModel = {
    _id?: string;
    title?: string;
    isCorrect?: boolean;
};

export type LearningModel = {
    _id?: string;
    category?: LearningCategoryEnum;
    showCategoryPicture?: boolean;
    visitingDate?: Date;
    affiliatedCategoryId?: CategoryEnum;
    title?: string;
    type?: LearningEnum;
    callToAction1?: string;
    callToAction2?: string;
    answers?: AnswerModel[];
    picture?: Observable<File>;
    pictureTouched?: boolean;
    pictureId?: string; // set to undefined if user overwrites an existing file
    thumbnail?: Observable<File>;
    thumbnailTouched?: boolean;
    thumbnailId?: string; // set to undefined if user overwrites an existing file
    solutionPicture?: Observable<File>;
    solutionPictureTouched?: boolean;
    solutionPictureId?: string; // set to undefined if user overwrites an existing file
    solutionPictureThumbnail?: Observable<File>;
    solutionPictureThumbnailTouched?: boolean;
    solutionPictureThumbnailId?: string; // set to undefined if user overwrites an existing file
    solutionRichContent?: string;
    highlight?: boolean;
    hasOnlyOneValidAnswer?: boolean;
    hideShowVotesButton?: boolean;
};

export type SlidePictureModel = {
    _id?: string;
    title?: string;
    file?: Observable<File>;
    fileTouched?: boolean;
    fileId?: string; // set to undefined if user overwrites an existing file
    thumbnail?: Observable<File>;
    thumbnailTouched?: boolean;
    thumbnailId?: string; // set to undefined if user overwrites an existing file
};

export type SlideModel = {
    _id?: string;
    category?: CategoryEnum;
    categoryId?: string;
    showCategoryPicture?: boolean;
    visitingDate?: Date;
    subheading?: string;
    title?: string;
    shortTitle?: string;
    richContent?: string;
    pictures?: SlidePictureModel[];
    highlight?: boolean;
    disclaimer?: string;
};

export type ReferenceModel = {
    _id?: string;
    id?: string;
    richContent?: string;
};

export type TagModel = {
    display: string;
    value: string;
};

export type PatientCaseDetailModel = {
    _id?: string;
    publish?: boolean;
    key?: string;
    title?: string;
    subtitle?: string;
    indication?: TagModel[];
    cancerType?: TagModel[];
    author?: TagModel[];
    gender?: Gender;
    age?: number;
    slides?: (SlideModel | LearningModel)[];
    references?: ReferenceModel[];
    version?: number;
};
